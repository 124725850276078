.select-filterable {
  display: inline-block; /* Allows the component to sit inline with other elements */
  position: relative;
  /* color: #9B51E0; */
  cursor: default;
  margin: 10px;
}

.select-filterable .query-box-input input {
  font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /* font-weight: 350; */
  font-size: 20px;
  color: #9B51E0;
  background-color: #fff;
  border: 1px solid #9B51E0;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  padding: 8px 10px;
  transition: all 200ms ease;
  width: 100%; /* Adjust width to container's size */
  min-width: 400px;
  max-width: 600px; /* Prevents excessive width */
}

.select-filterable .query-box-input input::placeholder {
  color: rgb(155, 81, 224, 0.7);
  font-size: 20px;
}

.select-filterable .query-box-arrow {
  border-color: rgba(155, 81, 224, 0.8) transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  /* width: 0; */
}

.select-filterable .query-box-arrow.open {
  border-color: transparent transparent rgba(155, 81, 224, 0.8);
  border-width: 0 5px 5px;
}

.select-filterable .options {
  display: none;
  background-color: #fff;
  border: 1px solid #9B51E0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-top: 4px; /* Space between input and dropdown */
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%; /* Ensures dropdown is below the input */
  left: 0;
  right: 0;
  z-index: 1000;
}

.select-filterable .options.open {
  display: block;
}

.select-filterable .option {
  padding: 8px 10px;
  /* color: #9B51E0; */
  cursor: pointer;
  font-size: 20px;
}

.select-filterable .option:hover,
.select-filterable .option.selected {
  background-color: rgba(155, 81, 224, 0.1);
}

@media (max-width: 800px) {
  .select-filterable .query-box-input input {
    min-width: 250px;
  }
}

@media (max-width: 600px) {
  .select-filterable .selected-value input {
    font-size: 18px;
  }

  .select-filterable .option {
    font-size: 18px;
  }

  .select-filterable .query-box-input input::placeholder {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .select-filterable .selected-value input {
    font-size: 16px;
  }

  .select-filterable .option {
    font-size: 16px;
  }

  .select-filterable .query-box-input input::placeholder {
    font-size: 16px;
  }
}
