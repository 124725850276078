.select {
  box-sizing: border-box;

  padding: 10px;
  padding-left: 20px;
  max-width: 100%;

  font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 350;
  font-size: 20px;
  color: #9B51E0;

  background-color: transparent;
  border: 0px solid;
  border-bottom: 1px solid #9B51E0;
}

@media (max-width: 600px) {
  .select {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .select {
    font-size: 14px;
  }
}