.forgotPassword {
  margin: auto;
  text-align: center;
}

.forgotPasswordForm input {
  width: 310px;
  margin: 10px;
}

.forgotPasswordButton {
  margin-top: 20px;
}



.resetPassword {
  margin: auto;
  text-align: center;
}

.resetPasswordForm input {
  width: 310px;
  margin: 10px;
}

.resetPasswordButton {
  margin-top: 20px;
}
