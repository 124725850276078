.textarea {
  box-sizing: border-box;
  min-height: 200px;

  padding: 10px;
  padding-left: 20px;
  width: 100%;
  max-width: 900px;

  font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 350;
  font-size: 20px;
  color: #9B51E0;
  background-color: rgba(155, 81, 224, 0.1);

  border: 0px solid;
  border: 1px solid #9B51E0;
  border-radius: 20px;
}
  
@media (max-width: 600px) {
  .textarea {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .textarea {
    font-size: 16px;
  }
}

.textarea:focus {
  outline: none;
}

.textarea::placeholder {
  color: rgb(155, 81, 224, 0.7);
  opacity: 1;
}

.invalid {
  color: #e05172;
}