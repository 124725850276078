.login {
  margin: auto;
  text-align: center;
}

.loginForm input {
  width: 310px;
  margin: 10px;
}

.loginButton {
  margin-top: 20px;
}