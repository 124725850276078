.button {
  all: unset;
  margin: 10px;
  background-color: #9B51E0;
  border-width: 1px;
  border-style: solid;
  border-color: #9B51E0;
  border-radius: 20px;
  flex-grow: 1;
  flex-basis: 0%;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  padding: 6px 18px 6px 18px;
  min-width: 80px;
}

@media (max-width: 600px) {
  .button {
    width: 200px;
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .button {
    width: max-content;
    font-size: 16px;
  }
}

.button:hover {
  background-color: #77398B;
}

.button.disabled {
  background-color: #846f8a;
  cursor: not-allowed;
}

.button.secondary {
  background-color: rgba(155, 81, 224, 0.1);
  color: #9B51E0;
  border-width: 1px;
  border-style: solid;
  border-color: #9B51E0;
}

.button.secondary:hover {
  background-color: rgba(155, 81, 224, 0.3);
}