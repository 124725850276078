.uploadFileIcon {
  vertical-align: middle;
  height: 23px;
  padding-left: 10px;
}

.filesPreview {
  max-width: 400px;
  margin: auto;
}

.previewFile {
  display: flex;
  margin: 10px 5px;
}

.previewIcon {
  width: 40px;
  margin: auto;
}

.previewIcon img {
  width: 40px;
  height: auto;
  max-height: 40px;
  vertical-align: middle;
}

.previewFilename {
  max-width: 300px;
  width: 280px;
  min-width: 115px;
  margin: auto 10px;
  text-align: left;
}

.previewDelete {
  width: 30px;
  margin: auto;
}

.previewDelete img {
  cursor: pointer;
  vertical-align: middle;
}

@media (max-width: 600px) {
  .previewFilename {
    width: 240px;
  }
}

@media (max-width: 400px) {
  .previewFilename {
    width: 200px;
  }
}
