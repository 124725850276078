.animalAgeSelection {
  display: flex;
  justify-content: center;
}

@media (max-width: 750px) {
  .animalAgeSelection {
    flex-direction: column;
  }
}

.diagnosisResult {
  border: 1px solid rgba(155, 81, 224);
  border-radius: 20px;
  white-space: pre-line;
  padding: 10px 30px;
}

.diagnosisResult p {
  text-align: justify;
}

.diagnosisResultCopyContainer {
  position: relative;
}

.diagnosisResultCopy {
  cursor: pointer;
}

.copiedText {
  position: absolute;
  left: 50%;
  transform: translate(25px, -125%);
}

.animal-breed-selection-spacer {
  padding: 20px;
}

@media (max-width: 620px) {
  .animal-breed-selection-spacer {
    display: none;
  }
}