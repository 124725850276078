.button-selector {
  all: unset;
  margin: 10px;
  background-color: rgba(155, 81, 224, 0.1);
  border: 1px solid rgba(155, 81, 224, 0.6);
  border-radius: 20px;
  flex-grow: 1;
  flex-basis: 0%;
  justify-content: center;
  color: rgba(155, 81, 224, 1);
  font-size: 20px;
  text-align: center;
  padding: 6px 18px 6px 18px;
  min-width: 150px;
}

@media (max-width: 600px) {
  .button-selector {
    min-width: 200px;
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .button-selector {
    min-width: 150px;
    font-size: 16px;
  }
}

.button-selector:disabled {
  cursor: not-allowed;
}

.button-selector:disabled:not(.selected) {
  background-color: #846f8a;
}

.selected {
  background-color: rgba(155, 81, 224, 0.85);
  color: #ffffff;
}

.button-selector:hover:not(:disabled) {
  background-color: rgba(155, 81, 224, 0.4);
  color: #9b51e0;
}
