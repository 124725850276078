/* Base tooltip styles */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s 0.5s ease-in-out, visibility 0s linear 500ms;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

/* Top tooltip */
.tooltip.top .tooltiptext {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip.top .tooltiptext::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: #555 transparent transparent transparent;
}

/* Bottom tooltip */
.tooltip.bottom .tooltiptext {
  top: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip.bottom .tooltiptext::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent #555 transparent;
}

/* Right tooltip */
.tooltip.right .tooltiptext {
  top: 50%;
  left: 125%;
  margin-top: -30px;
}

.tooltip.right .tooltiptext::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-color: transparent #555 transparent transparent;
}

/* Left tooltip */
.tooltip.left .tooltiptext {
  top: 50%;
  right: 125%;
  margin-top: -30px;
}

.tooltip.left .tooltiptext::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-color: transparent transparent transparent #555;
}

/* Show tooltip on hover */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}