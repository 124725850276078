
.diagnosisContent {
  max-width: 1000px;
  margin: auto;
  text-align: center;
  height: 100%;
}

.diagnosisLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.diagnosisBreadCrumb {
  flex: 0 0 auto;
}

.diagnosisSelection {
  flex: 0 0 auto;
}

.navigationButtonsContent {
  flex: 1 1 auto;
  position: relative;
  min-height: 80px;
}

.navigationButtons {
  position: absolute;
  bottom: 10px;
  width: 400px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media (max-width: 600px) {
  .navigationButtons {
    width: 100%;
  }
}

.navigationButtonsLeftIcon {
  padding-right: 10px;
  vertical-align: middle;
}

.navigationButtonsRightIcon {
  padding-left: 10px;
  vertical-align: middle;
}

.navigationButtonsSpace {
  display: block;
  width: 50%;
}
