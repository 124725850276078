
html {
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  margin: 0;

  font-weight: 300;
  font-size: 20px;
}

h1 {
  font-weight: 400;
  font-size: 36px;
}

h2 {
  font-weight: 400;
  font-size: 36px;
}

h3 {
  font-weight: 300;
  font-size: 24px;
}

.brand {
  font-weight: 300;
  font-size: 50px;
  margin: 32px 64px;
  padding: 5px;
  display: inline-block;
}

.brand img {
  width: 120px;
  cursor: pointer;
}

.profile {
  position: absolute;
  right: 10px;
  top: 45px;
  margin: 15px;
  padding: 5px;
}

.profile a {
  cursor: pointer;
  padding: 2px;
  text-decoration: none;
  color: inherit;
}

.profile a:link, .profile a:visited, .profile a:active {
  text-decoration: none;
}

.profile a:hover {
  text-decoration: underline;
}

.profile>span {
  cursor: pointer;
  margin-left: 15px;
  vertical-align: middle;
}

.content {
  flex: 1 1 auto;
  padding: 50px;
}

@media (max-width: 600px) {
  body {
    font-size: 16px;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 22px;
  }

  .profile a {
    display: none;
  }

  .content {
    padding: 25px;
  }
}

@media (max-width: 400px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 20px;
  }

  .content {
    padding: 6.25%;
  }
}