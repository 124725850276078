.register {
  margin: auto;
  text-align: center;
}

.registerForm input {
  width: 310px;
  margin: 10px;
}

.registerForm select {
  width: 310px;
  margin: 10px;
}

.registerButton {
  margin-top: 20px;
}

.registerInfos {
  display: flex;
  justify-content: center;
  color: #846f8a;
  font-size: 18px;
}

@media (max-width: 600px) {
  .registerInfos {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .registerInfos {
    font-size: 14px;
  }
}