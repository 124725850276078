
.feedback-dialog {
  width: 250px;
  height:200px;
  display: flex;

  position: fixed;
  bottom: 10px;
  right: 25px;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.feedback-dialog > img {
  width: 100%;
  height: 100%;
  display: block;
}

.feedback-content {
  position: absolute;
}

.feedback-buttons {
  display: flex;
  gap: 10px;
}

.feedback-buttons div {
  border: none;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 1.5em;
}

.thumbsdown-button img {
  padding-top: 10px;
}

.thumbsup-button img {
  padding-bottom: 10px;
}

@media (max-width: 930px) {
  .feedback-dialog {
    display: none;
  }
}
